import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import '../../pages/styles/Home.css'
import { FcDownload } from "react-icons/fc";
import IngB from "../../images/rentas/ingB.gif" 
import '../../pages/styles/Rentas.css';

class IngBrutos extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container test">    


                <div className="row justify-content-start" style={{marginTop:20}}>
                      <div className="col"></div>
                      <div className="col-md-8">
                        <h4>Aquí puedes descargar la documentación e información de Ingresos Brutos</h4>
                      </div>
                    
                      <div className="col-md-2">
                        <img
                            className="img-fluid mx-auto d-block"
                            alt="Ingresos Brutos"
                            src={IngB}
                            width="90%"
                        />                        
                      </div>

                      <div className="col"></div>
                </div>

                  <div className="row justify-content-center" style={{borderStyle:'solid',borderWidth:2,borderColor:'#58a5ba',marginBottom:20}}>


                  <div className="col-md-6 text-center">

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                    
                      <div className="col"></div>
                    
                      <div className="col-md-6">
                        <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Trámites Ingresos Brutos</b>
                      </div>
                      <div className="col-md-3">
                        <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/TRAMITES%20DE%20IIBB.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                      </div>
                    
                      <div className="col"></div>

                  </div>
                  {/*
                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>

                      <div className="col"></div>
                    
                      <div className="col-md-6">
                        <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario A Inscripción Reempadronamiento</b>
                      </div>
                      <div className="col-md-3">
                      <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/FORMULARIO%20A%20INSCRIP%20REEMPADRONAMIENTO%20IIBB.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                      </div>
                    
                      <div className="col"></div>
                  </div>
                  */}

                  {/*
                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                    <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario B - Solicitud Baja</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/FORMULARIO%20B-%20SOLICITUD%20BAJA.PDF','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>
                  */}

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Padrón Agentes de Retención</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/2025/PADRON_AGENTES_DE_RETENCION.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Mínimos Mensuales 2018 a 2025</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/2025/MINIMOS_MENSUALES_IIBB_2018_a_2025.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario de DDJJ (PDF Editable)</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/DDJJedit.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>
        
                  </div>

                  







                  <div className="col-md-6 text-center">

                

             

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario de DDJJ</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/Declaraci%C3%B3n%20Jurada%20Anual.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>    
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Nota Solicitud de Constancias</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/SOLICITUD%20DE%20CONSTANCIAS.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>   
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                    <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>NAES: Incluye-Excluye</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/naes.xls','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>    
                  </div>

                   <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                    <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>NAES: Alicuotas 2025</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/iibb/2025/NAES_con_ALICUOTAS_2025.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>    
                  </div>
                 
                  </div>
                
                  </div>

                

                
              </div>

            </React.Fragment>      
        )
    }
}

export default IngBrutos;